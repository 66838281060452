import { Controller } from "@hotwired/stimulus"
// app/javascript/controllers/chat_controller.js



export default class extends Controller {

    connect() {
        console.log("chat_controller.js: connect");
    }

  async sendMessage(event) {
    console.log("Event triggered by:", event.target);
    event.preventDefault();
    let input = this.element.querySelector('#chat-input');
    let message = input.value;
    
    console.log("chat_controller.js: sendMessage: message: ", message);
    let response = await fetch("/chat/ask", {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({ message: message }),
      headers: { 
        "Content-Type": "application/json",
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": getMetaValue("csrf-token")
      }
    });

    console.log("chat_controller.js: sendMessage: response: ", response);

    if (response.ok) {
      Turbo.clearCache();
      Turbo.renderStreamMessage(await response.text());
      input.value = "";  // Clear the chat input
    } else {
      console.error("Failed to send message");
    }
  }
}



function getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }