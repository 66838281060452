// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

// then, add these lines in app/javascript/application.js
import jquery from 'jquery'
// these lines add jQuery and $ to global variables so you can use $()
window.jQuery = jquery
window.$ = jquery

import * as bootstrap from "bootstrap"

import Chart from 'chart.js/auto'


import "./controllers"
import "trix"
import "@rails/actiontext"
